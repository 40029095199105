import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then( m => m.HomePageModule)
  },
  {
    path: 'step1',
    loadChildren: () => import('./step1/step1.module').then(m => m.Step1PageModule)
  },
  {
    path: 'step2',
    loadChildren: () => import('./step2/step2.module').then(m => m.Step2PageModule)
  },
  {
    path: 'step3',
    loadChildren: () => import('./step3/step3.module').then(m => m.Step3PageModule)
  },
  {
    path: 'step4',
    loadChildren: () => import('./step4/step4.module').then(m => m.Step4PageModule)
  },
  {
    path: 'step5',
    loadChildren: () => import('./step5/step5.module').then(m => m.Step5PageModule)
  },
  {
    path: 'step6',
    loadChildren: () => import('./step6/step6.module').then(m => m.Step6PageModule)
  },
  {
    path: 'step7',
    loadChildren: () => import('./step7/step7.module').then(m => m.Step7PageModule)
  },
  {
    path: 'step8',
    loadChildren: () => import('./step8/step8.module').then(m => m.Step8PageModule)
  },
  {
    path: 'step8extra',
    loadChildren: () => import('./step8extra/step8extra.module').then(m => m.Step8extraPageModule)
  },
  {
    path: 'step9',
    loadChildren: () => import('./step9/step9.module').then(m => m.Step9PageModule)
  },
  {
    path: 'step10',
    loadChildren: () => import('./step10/step10.module').then(m => m.Step10PageModule)
  },
  {
    path: 'step11',
    loadChildren: () => import('./step11/step11.module').then(m => m.Step11PageModule)
  },
  {
    path: 'step12',
    loadChildren: () => import('./step12/step12.module').then(m => m.Step12PageModule)
  },
  {
    path: 'report-details',
    loadChildren: () => import('./report/report-details.module').then(m => m.ReportDetailsPageModule)
  },
  {
    path: 'tech-support',
    loadChildren: () => import('./tech-support/tech-support.module').then(m => m.TechSupportPageModule)
  },
  {
    path: 'email',
    loadChildren: () => import('./report/email/email.module').then(m => m.EmailPageModule)
  },
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  {
    path: 'step8extra',
    loadChildren: () => import('./step8extra/step8extra.module').then( m => m.Step8extraPageModule)
  },
  {
    path: 'step6extra',
    loadChildren: () => import('./step6extra/step6extra.module').then( m => m.Step6extraPageModule)
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
