// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

//# Local ###

export const environment = {
  production: false,
  staging: false,
  development: true,
  urlapi: "https://proteusapi.fab-dev.com"
  
};


//# QUIMBY ###
/*
export const environment = {
  production: false,
  staging: false,
  development: true,
  urlapi: "https://proteusapi.fab-dev.com"
};
*/

//# UAT ###
/*
export const environment = {
  production: false,
  staging: true,
  development: false,
  urlapi: "https://proteusapi.fab-uat.com"
};
*/

//# LIVE ###
/*
export const environment = {
  production: true,
  staging: false,
  development: false,
  urlapi: "http://proteusapi.local:8074"
};
*/

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.

  //urlapi = "http://proteusapi.local:8074";
  //urlapi = "https://proteusapi.fab-uat.com";
  //urlapi = "https://proteusapi.fab-dev.com";

  //ionic build --prod
  //ng build -c=production
  //ng build -c=staging
  //ng build -c=development